import React, { useEffect, useState, useRef } from "react";
import { useIntersection } from "react-use";

export const IntersectionContext = React.createContext({ inView: true });

export const IntersectionObserver = ({
  Reset = false,
  children,
  reset = true,
  thresholdParam
}) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold: thresholdParam || 0.4,
  });

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    const inViewNow = intersection && intersection.isIntersecting;
    if (inViewNow && isMounted) {
      return setInView(inViewNow);
    } else if (reset && Reset !== false) {
      return setInView(false);
    }
    return () => { isMounted = false };
  }, [intersection, reset]);

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div className="relative w-full h-full" ref={intersectionRef}>{children}</div>
    </IntersectionContext.Provider>
  );
};