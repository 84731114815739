
import React from "react"
import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import { isMobileOnly, isMobile, isTablet } from "react-device-detect";
import "../../style/swiper.scss"
import 'swiper/components/scrollbar/scrollbar.scss';
import { useDispatch } from 'react-redux';

SwiperCore.use([Navigation, Pagination, Mousewheel, Scrollbar, A11y]);

const SwiperHorizontal = ({ children }) => {

    const dispatch = useDispatch();

    function clickmail() {
        dispatch({ type: 'SetVisible', value: false });
    }
    return (
        <>
            <Swiper
                spaceBetween={120}
                slidesPerView={3}
                speed={500}
                scrollbar={{ draggable: true }}
                direction='horizontal'
                centeredSlides={true}
                mousewheel={true}
                onSlideChange={(swiper) => swiper.isBeginning ? dispatch({ type: 'SetVisible', value: true }) : dispatch({ type: 'SetVisible', value: false })}
                slideToClickedSlide={false}
                navigation={{
                    nextEl: '.rightBtn',
                    prevEl: '.leftBtn',
                }}
                className="w-full h-screen"
            >
                {children}
            </Swiper>
        </>
    )
}

export default SwiperHorizontal