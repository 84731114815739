import React, { useContext, useEffect } from 'react';
import { graphql } from "gatsby"
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { motion, AnimatePresence } from "framer-motion"

import SEO from '../components/SEO/seo';
import SwiperHorizontal from "../components/swiper/SwiperHorizontal";
import { SwiperSlide } from 'swiper/react';
import { IntersectionContext, IntersectionObserver } from "../components/lib/intersection";
import * as AnimMotion from '../components/home/Prez/animation';
import { connect, useDispatch } from 'react-redux';
import SvgArrowRight from "../components/UI/SVG/button/arrowRight";
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkBox, LinkOverlay } from "@chakra-ui/react"

const MotionLinkBox = motion(LinkBox)


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allContentfulProjects(filter: {node_locale: {eq: $language }}, sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          Title
          description
          id
          projectUrl
          thumbnail
          {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              quality: 75
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

const ProjectsPage = ({ ProjectHeaderisVisible, ...props }) => {

  const { inView } = useContext(IntersectionContext);
  const dispatch = useDispatch();

  const { t } = useTranslation(['titles']);
  const context = useContext(I18nextContext);

  useEffect(() => {
    dispatch({ type: 'SetVisible', value: true })
  }, []);

  const parent = {
    base:
    {
      transition: {
        staggerChildren: 0.15,
      }
    },
    show:
    {
      transition: {
        staggerChildren: 0.3,
      }
    }
  }

  const shine = {
    base:
    {
      opacity: 0,
      scale: 2,
      transition: {
        duration: 0.5,
      }
    },
    hover: {
      opacity: 0.45,
      scale: 2,
      transition: {
        duration: 0.35,
      }
    }
  }

  const panel = {
    base:
    {
      y: -20,
      opacity: 0,
      transition: {
        duration: 0.2,
        delay: 0,
      }
    },
    show:
    {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      }
    },
    hidden:
    {
      opacity: 0.2,
    },
    tap: {
      scale: 0.95
    },
    hover: {
      y: -10,
      transition: {
        duration: 0.4,
      }
    }
  }

  const description = {
    base:
    {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
      }
    },
    hover: {
      opacity: 1,
      height: 30,
      transition: {
        type: "spring",
        damping: 8,
        mass: 0.6,
      }
    }
  }

  const gradient = {
    base:
    {
      opacity: 0.8,
      transition: {
        duration: 0.3,
      }
    },
    hover: {
      opacity: 1,
      transition: {
        duration: 0.3,
      }
    }
  }

  const phraseproject = Array.from(t('slider~Projects'));

  const animationText = {
    initial: { opacity: 0, x: 0, y: 20 },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: 'spring',
        damping: 10,
        stiffness: 20,
        mass: 0.5,
        duration: 0.25,
      },
    },
    exit: {
      opacity: 0,
      x: 0,
      y: 10,
      transition: {
        type: 'tween',
        duration: 0.35,
      },
    },
  }

  const headlinetext = {
    initial: {
      opacity: 0,
      x: 0,
      y: 20,
    },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 0.35,
        type: 'spring',
        staggerChildren: 0.08,
      },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: {
        duration: 1.5,
      },
    },
  };

  const arrow = {
    base:
    {
      opacity: 0,
      x: -10,
    },
    hover: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.25,
      },
    },
  };

  return (
    <>
      <SEO title={t('titles~Projects')} />
      <section className="home_gradient">
        <AnimatePresence>
          {ProjectHeaderisVisible &&
            <motion.div
              variants={animationText}
              initial="initial"
              animate="enter"
              exit="exit"
              className="w-full max-w-screen-xl h-full flex flex-col items-start justify-center mx-auto left-0 right-0 absolute top-0"
            >
              <motion.div
                variants={headlinetext}
                className="inline-flex"
              >
                <div className="inline-flex">
                  {phraseproject.map((letter, index) => (
                    <motion.h1
                      key={index}
                      width={"auto"} // Set the width to the width of the letter
                      height={50} // Set the height to the height of the text
                      background={""}
                      className="title-slide"
                      style={{ position: "relative" }} // Position elements
                      variants={animationText}
                    >
                      {letter === " " ? "\u00A0" : letter}
                    </motion.h1>
                  ))}
                </div>
              </motion.div>
              <div className="text-indigo-400 leading-relaxed tracking-wide font-brown py-12">
                <motion.div variants={AnimMotion.subtext}>
                  <p className="text-xl text-gradient font-bold">{t("slider~The wonderful references I've worked on")}<br></br></p>
                  <p className="text-xl font-light">{t('slider~Scroll, swipe, click... Whatever !')}</p>
                </motion.div>
              </div>
            </motion.div>
          }
        </AnimatePresence>
        <SwiperHorizontal>
          {props.data.allContentfulProjects.edges.map(edge => {
            return (
              <SwiperSlide className="h-5/6 mt-32 mb-16 cursor-pointer overflow-visible transition-all duration-300" key={edge.node.id}>
                <IntersectionObserver>
                  <MotionLinkBox initial="base" animate={inView ? "show" : "hidden"} whileHover="hover" whileTap="tap" variants={parent} className="relative w-full h-full ">
                    <LinkOverlay href={edge.node.projectUrl} className="relative  hover:border-b-2 border-indigo-500 bg-indigo-900 w-slide h-full hover:shadow-xl  z-30  rounded-md" target="_blank" rel="noopener noreferrer nofollow">
                      <motion.div variants={panel} className="relative  hover:border-b-2 border-indigo-500 bg-indigo-900 w-slide h-full hover:shadow-xl  z-30  rounded-md">
                        <div className=" pointer-events-none absolute  mx-10 mb-10 bottom-0 font-radiance z-40">
                          <h1 class="flex flex-row uppercase text-2xl text-white">{edge.node.Title}
                            <motion.div variants={arrow}><SvgArrowRight className="h-auto w-8 ml-5" /></motion.div></h1>
                          <motion.p variants={description} className=" text-sm mt-3 text-indigo-300 mr-20">{edge.node.description}</motion.p>
                        </div>
                        <motion.div className="absolute transition-opacity duration-300 w-full h-full overflow-hidden rounded-md top-0 left-0 bg-indigo-700  opacity-50 hover:opacity-100 " transition={{ duration: 0.5 }} >
                          <motion.div variants={gradient} className="w-full h-full absolute z-20" style={{ background: "linear-gradient(27deg, rgba(57,42,135,1) 0%, rgba(84,58,168,0.9528186274509804) 15%, rgba(44,33,88,0.06486344537815125) 100%)" }}></motion.div>
                          <GatsbyImage className="h-full" image={edge.node.thumbnail.gatsbyImageData} alt={edge.node.Title} />
                        </motion.div>
                      </motion.div>
                      <motion.div className="absolute left-0 top-0 z-2 gradient-blur-btn pointer-events-none w-full h-full" variants={shine}></motion.div>
                    </LinkOverlay>
                  </MotionLinkBox>
                </IntersectionObserver>
              </SwiperSlide>
            )
          })}
        </SwiperHorizontal>
      </section >
      {/* <Slideshow />
    <Slideshow /> */}
    </>
  )
};

export default connect(state => ({
  ProjectHeaderisVisible: state.ProjectHeader.isVisible,
}), null)(ProjectsPage);